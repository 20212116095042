<template>
  <div>
    <v-overlay :dark="false" :value="dialog" class="overlay">
      <img
        @click="$emit('update:dialog', mutableDialog), (loader = true)"
        id="close"
        src="@/assets/close.png"
      />

      <v-card class="card " elevation="0" v-if="showDeleteProduct == false">
        <v-row no-gutters align="start">
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-row class="search-row" align="start">
              <v-col cols="1" lg="1" md="1" sm="1" class="ml-4">
                <div class="bloc_liste">
                  <v-img
                    class="center-img mt-3"
                    v-bind:src="categorie ? categorie.url_logo : ''"
                    max-height="50"
                    max-width="50"
                  />
                  <p
                    v-html="categorie ? categorie.libelle : ''"
                    class="libelle_categorie mt-2"
                  >
                    <!-- {{categorie.libelle}} -->
                  </p>
                </div>
              </v-col>

              <v-col cols="10" lg="10" md="10" sm="10">
                <v-text-field
                  solo
                  dense
                  flat
                  placeholder="Réfrigérateur, chaise, placard..."
                  class="place"
                  v-model="search"
                >
                  <template class="searchField" slot="append">
                    <img class="search-logo" src="@/assets/search.png" />
                  </template>
                </v-text-field>

                <!-- <p> search : {{search}} / searchFromParent : {{searchFromParent}}</p> -->
              </v-col>
            </v-row>

            <v-row class="spacer"> </v-row>

            <!-- Lignes produits -->
            <v-row class="scroll">
              <template v-if="loader == true">
                <v-col cols="12" lg="12" md="12" sm="12" class="text-center">
                  <img class="search-logo" src="@/assets/loader.gif" />
                </v-col>
              </template>
              <template v-if="collectibles.length > 0">
                <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  v-for="collectible in collectibles"
                  :key="collectible.id_collectible"
                  @click="addCollectibleToCollecte(collectible)"
                >
                  <v-row
                    @click="$emit('update:dialog', $route.name !== 'administration' ? mutableDialog : true)"
                    class=" bloc-collectible"
                  >
                  
                    <v-col
                      cols="5"
                      lg="5"
                      md="5"
                      sm="5"
                      class="bloc-collectible-libelle"
                      align="start"
                    >
                      <span class="libelle">{{ collectible.libelle }}</span>
                    </v-col>

                    <v-col
                      cols="2"
                      lg="2"
                      md="2"
                      sm="2"
                      class="bloc-collectible-cubage"
                    >
                      <span
                        >{{ collectible.volume }} m&#179; -
                        {{ collectible.poids }} kg
                      </span>
                    </v-col>

                    <v-row
                      v-if="$route.name == 'administration'"
                      justify="end"
                      align="center"
                      class="mr-4"
                    >
                      <!-- <v-col
                        v-for="button in btn2"
                        :key="button.name"
                        cols="2"
                        lg="2"
                        md="2"
                        sm="2"
                        class="text-align-right mt-3"
                      > -->
                        <span
                          @click="callProduct(collectible)"
                          class="text-lowercase font-weight-bold"
                          text
                        >
                          {{ btn2[1].name }} -
                        </span>
                        <span
                          @click="collectibleToDelete = collectible, showDeleteProduct = true"
                          class="ml-2 text-lowercase font-weight-bold"
                          text
                        >
                          {{ btn2[2].name }}
                        </span>
                      <!-- </v-col> -->
                    </v-row>
                  </v-row>
                </v-col>
              </template>
              <template v-else>
                <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                  class="text-center"
                  v-if="loader == false"
                >
                  <p>Le produit demandé n'existe pas</p>
                </v-col>
              </template>
            </v-row>
            <!-- Lignes produits -->
          </v-col>
        </v-row>
      </v-card>

      <v-row v-if="$route.name == 'administration' && showDeleteProduct == false">
        <v-col cols="12">
          <v-btn
            @click="
              $emit('update:dialog', mutableDialog);
              showSecteurPop = true;
              titleSecteur = 'AJOUTER UN PRODUIT';
              fields[0].value = ''; fields[1].value = ''; fields[2].value = '';
            "
            color="#af2c70"
            dark
            width="100%"
            height="80"
            class="mt-10 py-5 font-weight-bold rounded-lg text-h4"
          >
            Ajouter un produit</v-btn
          >
        </v-col>
      </v-row>


    </v-overlay>

                <!-- popup suppression commune -->
    <v-overlay :value="showDeleteProduct">
      <v-card
        v-if="showDeleteProduct"
        class="modal_2 justify-center align-center"
        light
      >
        <v-row>
          <v-col cols="12">
            <h3 class="mt-5 title uppercasePopup">Supprimer ce produit ?</h3>
          </v-col>
          <v-col cols="4" offset="2">
            <v-btn
              v-on:click="
                deleteProd(collectibleToDelete),
                  (showDeleteProduct = false)
              "
              color="success"
              x-large
              >oui</v-btn
            ></v-col
          >
          <v-col cols="4" 
            ><v-btn v-on:click="showDeleteProduct = false" color="error" x-large
              >non</v-btn
            ></v-col
          >
        </v-row>
      </v-card>
    </v-overlay>

    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error.id"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error.msg }}
      </v-snackbar>
    </div>

    <AddProductPop
      :titleSecteur="titleSecteur"
      @close="showSecteurPop = false"
      :fields="fields"
      :btn2="btn2"
      :showSecteurPop="showSecteurPop"
      @validate="validate"
      @modify="modify"
      :snackbar="snackbar"
      :color="color"
      :timeout="timeout"
      errors="errors"
    />
  </div>
</template>
<script>
import api from "@/api/collectibles";
import AddProductPop from "@/components/popups/secteurPop";

export default {
  components: {
    AddProductPop,
  },

  props: {
    categorie: {
      type: Object,
      default: function() {
        return {
          url_logo: process.env.VUE_APP_BASE_URL + "categories/saisieLibre.png",
          libelle: "RECHERCHE PRODUIT",
        };
      },
    },
    dialog: [Boolean],
    mode: [String],
    searchFromParent: {
      type: String,
    },
  },

  data() {
    return {
      mutableDialog: this.dialog,
      collectibles: [],
      collectibleToDelete: {},
      mutableCollecteCollectibles: this.collecteCollectibles,
      search: this.searchFromParent,
      loader: true,
      defaultCategorie:
        process.env.VUE_APP_BASE_URL + "categories/saisieLibre.png",

      titleSecteur: "",
      showDeleteProduct: false,
      fields: [
        {
          label: "libelle",
          rules: "required",
          type: "text",
          value: "",
          placeholder: "Libellé *",
        },
        {
          label: "poids",
          rules: "required",
          type: "text",
          value: "",
          placeholder: "Poids *",
        },
        {
          label: "volume",
          rules: "required",
          type: "text",
          value: "",
          placeholder: "Volume *",
        },
        // {
        //   label: "prix",
        //   rules: "required",
        //   type: "text",
        //   value: "",
        //   placeholder: "Prix *",
        // },
      ],
      btn2: [
        { name: "Valider", method: "validate" },
        { name: "modifier", method: "modify" },
        { name: "supprimer", method: "deleteProd(collectible)" },
      ],
      showSecteurPop: false,
      currentProduct: {},
      color: "",
      snackbar: false,
      timeout: 3000,
      errors: [],
    };
  },
  watch: {
    async dialog(new_value, old_value) {
      if (new_value == true) {
        this.search = this.searchFromParent;
        await this.getCollectibles(this.searchFromParent);
        // document.getElementsByClassName("place")[0].textContent = this.searchFromParent;
      }

      console.log(old_value, "show résult sub", new_value);
    },

    async search(newValue, oldValue) {
      // this.search = this.search
      console.log("newValue", newValue, "oldValue", oldValue);
      if (newValue != oldValue) {
        await this.getCollectibles(newValue);
        //  this.searchFromParent = this.search
      }
    },
  },
  methods: {
    async getCollectibles(search) {
      if (this.mode == "allCollectibles") {
        api.getAllCollectibles(search).then((res) => {
          this.loader = false;
          this.collectibles = res.data.collectibles;
        });
      } else {
        api
          .getAllCollectiblesFromCategorie(this.categorie.id_categorie, search)
          .then((res) => {
            this.loader = false;
            this.collectibles = res.data.collectibles;
          });
      }
    },
    addCollectibleToCollecte(collectible) {
      collectible.quantity = 1;
      // this.$store.commit("addCollectibles", collectible);
      if (this.$store.state.collecteCollectibles.length > 0) {
        let isUpdate = 0;

        this.$store.state.collecteCollectibles.forEach((coll, index) => {
          if (collectible.id_collectible == coll.id_collectible) {
            isUpdate = 1;
            this.$store.commit("updateCollectible", {
              quantity: parseInt(coll.quantity) + 1,
              index: index,
            });
          }
        });
        if (isUpdate == 0) {
          this.$set(collectible, "quantity", 1);
          this.$store.commit("addCollectibles", collectible);
        }
      } else {
        this.$set(collectible, "quantity", 1);
        this.$store.commit("addCollectibles", collectible);
      }
      //this.mutableDialog = false;
      //this.$emit("update:dialog", this.mutableDialog);
    },

    validate(error) {
      if (error.valid) {
        let data = {};
        this.fields.forEach((field) => {
          data[field.label] = field.value;
        });

        data["id_categorie"] = this.categorie.id_categorie;

        api
          .createCollectible(data)
          .then(() => {
            this.color = "#93b122";
            this.errors = [];
            this.errors.push({
              msg: `Le produit ${this.fields[0].value} a été créé`,
              id: Math.random() * 100,
            });
            this.snackbar = true;
            this.showSecteurPop = false;
          })
          .catch((err) => {
            if (err.response.status == 500) {
              this.color = "red darken-2";
              this.errors.push({
                msg: "Ce produit existe déjà",
                id: Math.random() * 100,
              });
              this.snackbar = true;
            }
          });
      } else {
        console.log("erreur formulaire");
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }
    },

    deleteProd(collectible) {
      api
        .deleteCollectible(collectible.id_collectible)
        .then(() => {
          this.color = "#93b122";
          this.errors = [
            {
              msg: `Le produit ${collectible.libelle} a été supprimé`,
              id: Math.random() * 100,
            },
          ];
          this.snackbar = true;
          this.getCollectibles();
        })
        .catch((err) => {
          if (err.response.status == 500) {
            this.color = "red darken-2";
            this.errors.push({
              msg: "Impossible de supprimer ce produit",
              id: Math.random() * 100,
            });
            this.snackbar = true;
          }
        });
    },

    modify(error) {
      if (error.valid) {
        let data = {};
        this.fields.forEach((field) => {
          data[field.label] = field.value;
        });
        data["id_categorie"] = this.categorie.id_categorie;

        api
          .modifyCollectible(data, this.currentProduct)
          .then(() => {
            this.color = "#93b122";
            this.errors = [];
            this.errors = [
              {
                msg: `Le produit ${this.fields[0].value} a été modifié`,
                id: Math.random() * 100,
              },
            ];
            this.snackbar = true;
            this.showSecteurPop = false;
          })
          .catch((err) => {
            if (err.response.status == 500) {
              this.color = "red darken-2";
              this.errors.push({
                msg: "Impossible de modifié ce produit",
                id: Math.random() * 100,
              });
              this.snackbar = true;
            }
          });
      } else {
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }
    },

    callProduct(collectible) {
      api.getCollectible(collectible.id_collectible).then((res) => {
        this.currentProduct = collectible.id_collectible;
        console.log(this.currentProduct);
        this.fields = [
          {
            label: "libelle",
            rules: "required",
            type: "text",
            value: res.data.collectible.libelle,
            placeholder: "Libellé *",
          },
          {
            label: "poids",
            rules: "required",
            type: "text",
            value: res.data.collectible.poids,
            placeholder: "Poids *",
          },
          {
            label: "volume",
            rules: "required",
            type: "text",
            value: res.data.collectible.volume,
            placeholder: "Volume *",
          },
          // {
          //   label: "prix",
          //   rules: "required",
          //   type: "text",
          //   value: res.data.collectible.prix,
          //   placeholder: "Prix *",
          // },
        ];
        console.log("fields ", this.fields);

        this.titleSecteur = "MODIFIER UN PRODUIT";
        this.$emit("update:dialog", this.mutableDialog);
        this.showSecteurPop = true;
      });
    },
  },
};
</script>

<style lang="css" scoped>
/* .overlay {
    height: 100%;
} */

.card {
  width: 58.71vw;
  max-width: 1088px;
  height: 63.22vh;
  max-height: 612px;
}

#close {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 1;
  cursor: pointer;
}

.bloc_liste {
  position: absolute;
  top: -10px;
  left: -10px;

  width: 7vw;
  max-width: 130px;
  background-color: #ffffff;
  height: 115px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  text-align: center;
}

.libelle_categorie {
  font-family: "Roboto", sans-serif;
  color: #333333 !important;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  font-size: 1rem;
  margin-top: -6px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 18px;
}

.center-img {
  margin-left: auto;
  margin-right: auto;
}

.search-logo {
  padding-right: 40px;
}

.place {
  font: italic normal 100 1.71rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
  margin-top: 25px;
  margin-left: 1vw;
}

::v-deep .v-input {
  font: italic normal 100 1.71rem/2.14rem Roboto;
  border: 1px solid #e3e3e3 !important;
  border-radius: 15px !important;
  height: 65px;
}

::v-deep .place input::placeholder {
  font: italic normal bold 1.71rem/2.14rem Roboto !important;
  letter-spacing: 0px;
  color: #333333 !important;
}

::v-deep .v-input__slot {
  margin-top: 10px !important;
}

::v-deep .v-text-field__slot {
  margin-left: 20px;
}

/* ::v-deep .v-text-field__slot {
	margin-top: 13px;
	padding-left: 1vw;
} */

.text-align-right {
  text-align: right !important;
}

.bloc-collectible {
  border: 1px solid #e3e3e3;
  width: 98%;
  margin-left: 0.53vw;
  cursor: pointer;
}

.scroll {
  /* height: auto; */
  /* min-height: 46vh; */
  max-height: 46vh;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}

.bloc-collectible-libelle {
  font: normal normal bold 1rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333;
  text-transform: uppercase;
}

.bloc-collectible-cubage {
  font: italic normal bold 1rem/2.14rem Roboto;
  letter-spacing: 0px;
  /* color: #666666; */
  opacity: 1;
  margin-top: auto;
  margin-bottom: auto;
}

.libelle {
  margin-left: 1.94vw;
}

.spacer {
  height: 2vh;
}

@media screen and (max-width: 1366px) {
  ::v-deep .v-card[data-v-c346a3cc] {
    height: 60vh;
    margin-top: 50px;
  }
}

@media screen and (max-width: 1080px) {
  .libelle {
    margin-left: 0;
  }

  .bloc_liste {
    width: 81px;
  }
}

@media screen and (max-width: 810px) {
  .overlay {
    margin-right: 100px;
    margin-left: auto;
  }
  .card {
    width: 68.71vw;
    max-width: 1088px;
    height: 63.22vh;
    max-height: 612px;
  }

  .search-logo {
    padding-right: 0px;
  }

  .bloc_liste {
    width: 81px;
  }

  #close {
    width: 10%;
  }

  .scroll {
    /* height: auto; */
    max-height: 45vh;
  }
}
</style>
